import { notify } from './notify'
import { ErrorEvent } from './event'
import onerror from './onerror'
import unhandledRejection from './unhandled-rejection'
import vueErrorHandler from './vue'

window.rbcOnError = {
    load: (options, win = window, logger = window.console) => {
        onerror(win).load({ logger, notify, ErrorEvent, options })
        unhandledRejection(win).load({ logger, notify, ErrorEvent, options })
    },
    vue: (Vue, options, win = window, logger = window.console) =>
        vueErrorHandler(win).load({ logger, notify, ErrorEvent, options }, Vue),
}
