import ErrorStackParser from 'error-stack-parser'
import StackGenerator from 'stack-generator'
import hasStack from './core/has-stack'
import reduce from './core/reduce'
import filter from './core/filter'
import isError from 'iserror'

export class ErrorEvent {
    // eslint-disable-next-line default-param-last
    constructor(errorClass, errorMessage, stacktrace = [], originalError) {
        this.originalError = originalError
        this.metadata = {}

        this.ignore = function () {
            return false
        }

        this.error = {
            errorClass: ensureString(errorClass),
            errorMessage: ensureString(errorMessage),
            stacktrace: reduce(
                stacktrace,
                (accum, frame) => {
                    const f = formatStackframe(frame)
                    // don't include a stackframe if none of its properties are defined
                    try {
                        if (JSON.stringify(f) === '{}') return accum
                        return accum.concat(f)
                    } catch (e) {
                        return accum
                    }
                },
                [],
            ),
        }
    }

    addIgnore(options) {
        if (options && typeof options.ignore === 'function') {
            this.ignore = options.ignore
        }
        return this
    }

    shouldIgnore() {
        if (typeof this.ignore !== 'function') {
            return false
        }
        return this.ignore(this.toJSON())
    }

    addSource(source) {
        this.source = source
        return this
    }

    addDevice(nav = navigator) {
        this.device = {
            locale: nav.browserLanguage || nav.systemLanguage || nav.userLanguage || nav.language,
            userAgent: nav.userAgent,
            time: Date.now(),
        }
        return this
    }

    addLocation(win = window) {
        this.location = win.location
        return this
    }

    addMetadata(data) {
        this.metadata = data
    }

    toJSON() {
        return {
            source: this.source || 'unknown',
            metadata: this.metadata,
            exception: {
                name: this.error.errorClass,
                message: this.error.errorMessage,
                originalError: this.originalError,
                stacktrace: this.error.stacktrace,
            },
            location: this.location || 'unknown',
            device: this.device,
        }
    }
}

// takes a stacktrace.js style stackframe (https://github.com/stacktracejs/stackframe)
// and returns a Bugsnag compatible stackframe (https://docs.bugsnag.com/api/error-reporting/#json-payload)
const formatStackframe = (frame) => {
    const f = {
        file: frame.fileName,
        method: normaliseFunctionName(frame.functionName),
        lineNumber: frame.lineNumber,
        columnNumber: frame.columnNumber,
        code: undefined,
        inProject: undefined,
    }
    // Some instances result in no file:
    // - calling notify() from chrome's terminal results in no file/method.
    // - non-error exception thrown from global code in FF
    // This adds one.
    if (f.lineNumber > -1 && !f.file && !f.method) {
        f.file = 'global code'
    }
    return f
}

const normaliseFunctionName = (name) => (/^global code$/i.test(name) ? 'global code' : name)
const ensureString = (str) => (typeof str === 'string' ? str : '')

// Helpers

ErrorEvent.getStacktrace = function (error, errorFramesToSkip, backtraceFramesToSkip) {
    if (hasStack(error)) return ErrorStackParser.parse(error).slice(errorFramesToSkip)
    // error wasn't provided or didn't have a stacktrace so try to walk the callstack
    try {
        return filter(
            StackGenerator.backtrace(),
            (frame) => (frame.functionName || '').indexOf('StackGenerator$$') === -1,
        ).slice(1 + backtraceFramesToSkip)
    } catch (e) {
        return []
    }
}

ErrorEvent.create = function (
    maybeError,
    tolerateNonErrors,
    component,
    // eslint-disable-next-line default-param-last
    errorFramesToSkip = 0,
    logger,
) {
    const [error, internalFrames] = normaliseError(maybeError, tolerateNonErrors, component, logger)
    let event
    try {
        const stacktrace = ErrorEvent.getStacktrace(
            error,
            // if an error was created/throw in the normaliseError() function, we need to
            // tell the getStacktrace() function to skip the number of frames we know will
            // be from our own functions. This is added to the number of frames deep we
            // were told about
            internalFrames > 0 ? 1 + internalFrames + errorFramesToSkip : 0,
            // if there's no stacktrace, the callstack may be walked to generated one.
            // this is how many frames should be removed because they come from our library
            1 + errorFramesToSkip,
        )
        event = new ErrorEvent(error.name, error.message, stacktrace, maybeError)
    } catch (e) {
        event = new ErrorEvent(error.name, error.message, [], maybeError)
    }
    if (error.name === 'InvalidError') {
        event.addMetadata(`${component}`, 'non-error parameter', makeSerializable(maybeError))
    }
    return event
}

const makeSerializable = (err) => {
    if (err === null) return 'null'
    if (err === undefined) return 'undefined'
    return err
}

const normaliseError = (maybeError, tolerateNonErrors, component, logger) => {
    let error
    let internalFrames = 0

    const createAndLogInputError = (reason) => {
        if (logger) logger.warn(`${component} received a non-error: "${reason}"`)
        const err = new Error(
            `${component} received a non-error. See "${component}" tab for more detail.`,
        )
        err.name = 'InvalidError'
        return err
    }

    // In some cases:
    //
    //  - the promise rejection handler (both in the browser and node)
    //  - the node uncaughtException handler
    //
    // We are really limited in what we can do to get a stacktrace. So we use the
    // tolerateNonErrors option to ensure that the resulting error communicates as
    // such.
    if (!tolerateNonErrors) {
        if (isError(maybeError)) {
            error = maybeError
        } else {
            error = createAndLogInputError(typeof maybeError)
            internalFrames += 2
        }
    } else {
        switch (typeof maybeError) {
            case 'string':
            case 'number':
            case 'boolean':
                error = new Error(String(maybeError))
                internalFrames += 1
                break
            case 'function':
                error = createAndLogInputError('function')
                internalFrames += 2
                break
            case 'object':
                if (maybeError !== null && isError(maybeError)) {
                    error = maybeError
                } else if (maybeError !== null && hasNecessaryFields(maybeError)) {
                    error = new Error(maybeError.message || maybeError.errorMessage)
                    error.name = maybeError.name || maybeError.errorClass
                    internalFrames += 1
                } else {
                    error = createAndLogInputError(
                        maybeError === null ? 'null' : 'unsupported object',
                    )
                    internalFrames += 2
                }
                break
            default:
                error = createAndLogInputError('nothing')
                internalFrames += 2
        }
    }

    if (!hasStack(error)) {
        // in IE10/11 a new Error() doesn't have a stacktrace until you throw it, so try that here
        try {
            throw error
        } catch (e) {
            if (hasStack(e)) {
                error = e
                // if the error only got a stacktrace after we threw it here, we know it
                // will only have one extra internal frame from this function, regardless
                // of whether it went through createAndLogInputError() or not
                internalFrames = 1
            }
        }
    }

    return [error, internalFrames]
}

const hasNecessaryFields = (error) =>
    (typeof error.name === 'string' || typeof error.errorClass === 'string') &&
    (typeof error.message === 'string' || typeof error.errorMessage === 'string')
