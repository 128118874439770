export default (win = window) => ({
    load(rbcLog, Vue) {
        const prev = Vue.config.errorHandler

        Vue.config.errorHandler = (err, vm, info) => {
            const event = rbcLog.ErrorEvent.create(err, true, 1)

            event.addMetadata({
                errorInfo: info,
                component: vm ? formatComponentName(vm, true) : undefined,
                props: vm ? vm.$options.propsData : undefined,
            })
            event
                .addSource('vue')
                .addIgnore(rbcLog.options)
                .addLocation(win)
                .addDevice(win.navigator)

            if (event.shouldIgnore()) {
                return
            }

            rbcLog.notify(event, win, rbcLog.logger)

            if (rbcLog.logger && typeof rbcLog.logger.error === 'function') {
                rbcLog.logger.error(err)
            }

            if (typeof prev === 'function') prev.call(this, err, vm, info)
        }
    },
})

// taken and reworked from Vue.js source
const formatComponentName = (vm, includeFile) => {
    if (vm.$root === vm) return '<Root>'
    const options =
        typeof vm === 'function' && vm.cid != null
            ? vm.options
            : vm._isVue
            ? vm.$options || vm.constructor.options
            : vm || {}
    let name = options.name || options._componentTag
    const file = options.__file
    if (!name && file) {
        const match = file.match(/([^/\\]+)\.vue$/)
        name = match && match[1]
    }

    return (
        (name ? '<' + classify(name) + '>' : '<Anonymous>') +
        (file && includeFile !== false ? ' at ' + file : '')
    )
}

// taken and reworked from Vue.js source
const classify = (module.exports.classify = (str) =>
    str.replace(/(?:^|[-_])(\w)/g, (c) => c.toUpperCase()).replace(/[-_]/g, ''))
