import isError from 'iserror'

export default (win = window) => ({
    load: (rbcLog) => {
        win.addEventListener('unhandledrejection', (evt) => {
            const event = rbcLog.ErrorEvent.create(
                evt.reason,
                false,
                'unhandledrejection handler',
                1,
                rbcLog.logger,
            )
            event
                .addSource('unhandledrejection')
                .addIgnore(rbcLog.options)
                .addLocation(win)
                .addDevice(win.navigator)

            if (isError(event.originalError) && !event.originalError.stack) {
                event.addMetadata({
                    [Object.prototype.toString.call(event.originalError)]: {
                        name: event.originalError.name,
                        message: event.originalError.message,
                        code: event.originalError.code,
                    },
                })
            }

            if (event.shouldIgnore()) {
                return
            }

            rbcLog.notify(event, win, rbcLog.logger)
        })
    },
})
