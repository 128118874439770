import safeStringify from 'fast-safe-stringify'

const PIIKeys = [
    'dateOfBirth',
    'phoneNumber',
    'ssn',
    'emailAddress',
    'zipCode',
    'password',
    'newPassword',
    'confirmPassword',
    'currentPassword',
    'birthDate',
    'selectedDateOfBirth',
    'selectedBirthDate',
    'lastFourOfSsn',
    'selectedSsn',
    'selectedEmailAddress',
    'email',
    'selectedPhoneNumber',
    'phone',
    'mobilePhone',
]

function replacer(key, value) {
    if (PIIKeys.includes(key)) {
        return '[Removed]'
    }
    return value
}
export const httpRequest = (win = window, logger = window.console) => ({
    sendEvent: (event, cb = () => {}) => {
        try {
            const req = new win.XMLHttpRequest()
            req.onreadystatechange = function () {
                if (req.readyState === win.XMLHttpRequest.DONE) cb(null)
            }
            const loggingEndpoint = process.env.LOG_NOTIFY_ENDPOINT || '/log/v1/notify'
            req.open('POST', loggingEndpoint)
            req.setRequestHeader('Content-Type', 'application/json')
            if (win.idjwt) {
                req.setRequestHeader('idjwt', win.idjwt)
            }
            if (win['iv-user']) {
                req.setRequestHeader('iv-user', win['iv-user'])
            }
            req.send(safeStringify(event, replacer))
        } catch (e) {
            if (logger && typeof logger.error === 'function') {
                logger.error(e)
            }
        }
    },
})
